.profile-picture {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2rem;

    figure {
        margin-right: var(--gutter-small);
        flex-grow: 0;
        flex-shrink: 0;

        img {
            display: block;
            width: 100px;
            height: 100px;
            border-radius: 100%;
        }
    }

    profile-picture {
        margin-right: var(--gutter-small);
    }

    .options {
        flex-grow: 1;
        flex-shrink: 1;

        button {
            margin-bottom: var(--gutter-tiny);
        }

        .remove {
            font-weight: 700;
            color: var(--color-primary-300);
        }
    }

    @media screen and (max-width: 650px) {
        flex-direction: column;
        figure {
            margin-bottom: var(--gutter-small);
        }
    }
}
