ingredients-list {
    fieldset {
        margin: 0 0 var(--gutter-small) 0;
        padding: 0;
        border: none;

        label {
            display: flex;
            margin-bottom: var(--gutter-tiny);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
