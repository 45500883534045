page-profile {
    figure.profile-picture {
        display: block;

        img {
            max-height: 250px;
        }
        @media screen and (max-width: 900px) {
            img {
                max-height: 150px;
                width: auto;
            }
        }
    }

    .level-line {
        display: flex;
        span {
            margin: 12px 20px;
            font-size: 1.2rem;
            font-weight: bold;
        }
    }

    profile-headline {
        display: block;
        margin-bottom: 15px;

        h2 {
            margin-bottom: 0;
        }

        profile-badges {
            display: block;
            width: 100%;
            text-align: center;
        }
    }

    profile-followers {
        display: flex;
        justify-content: center;
        gap: var(--gutter-small);
        margin-bottom: var(--gutter-tiny);

        a,
        a:visited {
            text-decoration: none;
            color: unset;

            &.link {
                color: var(--color-accent-300);
            }
        }
    }
}

page-profile-selectorfollowers {
    tabs-indicator {
        margin-bottom: var(--gutter-tiny);
    }

    div.single-connection-wrapper {
        width: 100%;
    }

    single-connection {
        flex-direction: row;
        margin-bottom: var(--gutter-tiny);
        width: 100%;

        .info {
            text-align: unset;

            .group {
                justify-content: unset;
            }
        }

        .controls {
            margin: 0;
            margin-left: auto;
        }

        .shimmer-flex {
            flex: 1;
        }

        app-shimmer {
            &.image.circle {
                width: 60px;
                height: 60px;
            }
        }
    }
}
