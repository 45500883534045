html {
    overscroll-behavior: none;
}

qogni-app {
    .pull-to-refresh {
        z-index: 9999;
        width: 100%;
        padding: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        transition: all var(--transition-duration) ease-in-out;
        visibility: hidden;
        height: 0;

        &.visible {
            visibility: visible;
            padding: var(--gutter-small) 0;
            height: 60px;
        }
    }
}
