page-network > section.card {
    padding: var(--gutter-smaller);
}

connections-invite {
    background: var(--color-primary-200);
    padding: var(--gutter-small) var(--gutter-small) 0 var(--gutter-small);
    border-radius: var(--radius-large);
    display: block;
    text-align: center;

    .invite-trigger {
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        background: var(--color-primary-100);
        margin: 0 auto var(--gutter-small) auto;

        .icon:before {
            font-size: 24px;
        }
    }

    img {
        display: block;
        margin: 0 auto;
        width: 75%;
        object-fit: contain;
        object-position: center bottom;
    }
}

connection-search {
    display: block;
    margin-bottom: var(--gutter-small);

    img{
        border-radius: 100%;
        aspect-ratio: 1/1;
    }
}

connections-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--gutter-smaller);
}

@media all and (min-width: 900px) {
    connections-list {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media all and (max-width: 600px) {
    connections-list {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media all and (max-width: 400px) {
    connections-list {
        grid-template-columns: 1fr;
    }
}

single-connection {
    background: var(--color-primary-200);
    padding: var(--gutter-small);
    border-radius: var(--radius-large);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gutter-tiny);

    a {
        text-decoration: unset;
        color: unset;
    }

    h4 {
        margin-bottom: var(--gutter-mini);
    }

    figure {
        width: 60px;
        height: 60px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
            border: 2px solid var(--color-primary-200);
            border-radius: 100%;
        }
    }

    .info {
        text-align: center;

        .group {
            justify-content: center;
        }
    }

    .group {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 600;
        gap: var(--gutter-small);

        svg-icon {
            margin-right: var(--gutter-mini);
        }

        .related,
        .connections {
            display: flex;
            align-items: center;
        }

        .related svg-icon {
            --icon-fill-color: var(--color-accent-400);
        }

        .connections svg-icon {
            --icon-fill-color: var(--color-accent-300);
        }
    }

    .controls {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: auto;

        > * {
            margin-bottom: var(--gutter-mini);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

html.is-mobile connections-list {
    grid-template-columns: repeat(2, 1fr);
}
