@font-face {
    font-family: InterVariable;
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url("/assets/fonts/InterVariable.woff2") format("woff2");
}
@font-face {
    font-family: InterVariable;
    font-style: italic;
    font-weight: 100 900;
    font-display: swap;
    src: url("/assets/fonts/InterVariable-Italic.woff2") format("woff2");
}

base,
html {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.75;
    color: var(--color-primary-000);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
    font-family: var(--font-titles);
    margin: 0 0 var(--gutter-small) 0;
    line-height: 1.1;
    font-weight: 700;
}

h1,
.h1 {
    font-size: 40px;
}

h2,
.h2 {
    font-size: 24px;
}

h3,
.h3 {
    font-size: 20px;
}

h4,
.h4 {
    font-size: 18px;
    margin-bottom: 10px;
}

h5,
.h5 {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 300;
}

p {
    margin: 0 0 var(--gutter-small) 0;
}
