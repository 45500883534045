page-ai-buddy {
    display: block;

    button.menu-btn {
        position: absolute;
        top: var(--gutter-tiny);
        left: 0;
    }

    session-item {
        display: block;
        cursor: pointer;
        

        span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            padding: var(--gutter-tiny, 5px);
        }

        :hover {
            background-color: var(--color-pastel-primary-000);
        }

        &.active {
            background-color: var(--color-pastel-accent-300);
        }
    }

    [data-flow-inner] {
        height: calc(100dvh - 60px);
    }

    .enable-speech{
        position: absolute; top: 20px; right: 11px
    }

    flow-ui[type="chat"] [data-flow-inner] flow-ui-step {
        margin-left: 1rem;
        margin-right: 1rem;

        &.running:has(form) {
            padding: 0;
            margin: 0;
            width: 100%;

            form {
                padding: 0;
                left: -80px;

                [data-flow-continue] button {
                    right: 0px;
                    bottom: 30px;
                    padding: 0.5rem 1rem;
                    border: none;
                }
            }
        }

        &.hide-when-completed {
            display: inline-block;
            max-width: 300px;
            font-weight: 800;
            font-size: large;
            background: linear-gradient(rgba(255, 0, 0, 1), rgba(0, 0, 0, 0));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &.completed {
            &.hide-when-completed {
                display: none;
                opacity: 0;
                zoom: 0;
                transition: all 0.5s ease-out;
            }
        }
    }

    .pre-wrap:has(p,ul,ol) {
        white-space: unset;
    }
}

flow-ui[type="chat"] {
    display: flex;
    flex-direction: column-reverse;

    [data-flow-inner] {
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .footnotes {
        font-size: smaller;
        text-align: right;
        line-height: 1.1rem;
        font-style: italic;

        dl {
            display: inline;
            margin: 0;
            padding: 0;
        }
        dt {
            display: none;
        }
        dd {
            font-weight: unset;
            display: inline;
        }
    }

    .chat-box {
        min-height: unset;
    }

    form {
        background-color: transparent;

        [data-flow-continue] {
            margin: 0;

            position: relative;

            [name="back"] {
                display: none;
            }

            textarea {
                padding-right: 60px;
            }

            button {
                position: absolute;
                bottom: 40px;
                right: 15px;
                background-color: transparent;
                color: inherit;
                box-shadow: none;
            }
        }
    }

    time {
        display: block;
        width: 100%;
        text-align: right;
        margin-top: 1rem;
        font-size: small;
        opacity: 0.5;
    }
}

@media only screen and (max-width: 600px) {
    page-ai-buddy {
        flow-ui[type="chat"] [data-flow-inner] flow-ui-step {
            &.running:has(form) {
                form {
                    padding: 0;
                    margin-left: 20px;
                    width: calc(100vw - 40px);
                }
            }
        }
    }
}
