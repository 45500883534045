::-webkit-scrollbar {
    width: 8px;
    height: 8px;

    &-button {
        width: 33px;
        height: 33px;
    }

    &-thumb {
        background: rgba(127, 127, 127, 0.2);
        border-radius: 10rem;
        cursor: pointer;
    }

    &-thumb:hover {
        background: rgba(229, 66, 113, 0.5);
    }

    &-thumb:active {
        background: rgba(229, 66, 113, 0.8);
    }

    &-track {
        background: rgba(0, 0, 0, 0.2);
        border: 22px none rgba(255, 255, 255, 0.2);
        border-radius: 50px;
    }

    &-track:hover {
        background: rgba(0, 0, 0, 0.2);
    }

    &-track:active {
        background: rgba(0, 0, 0, 0.2);
    }

    &-corner {
        background: transparent;
    }
}
