image-card {
    figure {
        position: relative;
        display: block;

        &:before {
            content: '';
            display: block;
            padding-top: 56.25%;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: var(--radius-large);
            object-position: center;
            object-fit: cover;
        }

        span.source {
            position: absolute;
            right: 8px;
            bottom: 8px;
            z-index: 5;
            font-size: 0.7rem;
        }
    }
}
