
// onboarding-container {
//     display: block;
//     width: 100%;
//     margin: 0 auto;
//     text-align: center;

//     img.footer {
//         width: 75%;
//         position: fixed;
//         z-index: 1;
//         margin: 0 auto;
//         max-height: 400px;

//         max-width: 100%;
//         height: auto;
//         bottom: 0;
//         left: 50%;
//         transform: translateX(-50%);
//     }

//     @media screen and (max-width: 800px) {
//         img.footer {
//             width: 100%;
//         }
//     }

//     section {
//         display: block;
//         position: relative;
//         text-align: left;
//         z-index: 20;
//     }
// }

// @media screen and (min-width: 800px) {
//     onboarding-container {
//         width: 80%;
//     }
// }

// @media screen and (min-width: 900px) {
//     onboarding-container {
//         width: 70%;
//     }
// }

// @media screen and (min-width: 1000px) {
//     onboarding-container {
//         width: 60%;
//     }
// }

// step-indicator {
//     display: flex;
//     margin-bottom: var(--gutter-small);

//     a {
//         text-align: center;
//         text-decoration: none;
//         color: var(--color-text-100);
//         width: 50%;
//         padding: var(--gutter-small) var(--gutter-tiny);

//         border-bottom: var(--border-size-large) solid var(--color-border-100);

//         &[data-current] {
//             color: var(--color-text-000);
//             border-color: var(--color-border-accent-100);
//         }

//         &[data-finished] {
//             border-color: var(--color-border-accent-200);
//         }
//     }
// }

flow-ui radio-group fieldset {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    // justify-content: space-around;

    label {
        // width: 160px;
        margin-bottom: 0.7rem;

        &:has(input.variant2[type=radio]) {
            margin: 0;
        }


        > span {
            display: flex;
            flex-direction: row;
            gap: .5rem;
            overflow: hidden;
            height: 52px;

            svg {

                width: 52px;
                height: 52px;
                clip-path: circle(30%);

            }
        }
    }

    @media all and (max-width: 450px) {
        // gap: 0.3rem !important;
        justify-content: center;

        label {
            width: 140px !important;

            > span {
                gap: 0 !important;
                height: auto !important;
                padding: 10px 10px !important;
            }
        }
    }
}

radio-group.wide-on-mobile {
    @media all and (max-width: 450px) {
        label {
            width: 100% !important;
        }
    }
}

radio-group#languages {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-around;

    label {
        width: 160px;
        margin-bottom: 0.7rem;

        // &:has(input.variant2[type=radio]) span:not(.no-before)::before{
        //     display: none;
        // }

        > span {
            display: flex;
            flex-direction: row;
            gap: .5rem;
            overflow: hidden;
            height: 52px;

            svg {

                width: 52px;
                height: 52px;
                clip-path: circle(30%);

            }
        }
    }

    @media all and (max-width: 410px) {
        gap: 0.3rem;
        justify-content: center;

        label {
            width: 140px;

            > span {
                gap: 0;
                height: 42px;

                svg {
                    margin-left: 15px;
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}

[data-flow-ui-type] form fieldset:has(social-rating) {
    display: grid;
}

html.is-mobile {
    flow-ui-step fieldset[data-flow-continue] button[name='back'] {
        width: 52px;
        height: 52px;
        padding: 0;
    
        span.arrow {
            margin-left: 0;
        }
    }
}
