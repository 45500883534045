page-offline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--gutter-small);

    & > * {
        margin-bottom: 0;
    }
}
