master-detail {
    background-color: var(--color-primary-100);
    width: 100%;
    position: fixed;
    top: 0;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100vh;
    z-index: 10;
}

.app-canvas {
    master-detail {
        height: 100%;
        min-height: 100vh;
        padding: var(--gutter-small) var(--gutter-small) 100px
            var(--gutter-small);
    }
}

.is-authenticated {
    .app-canvas {
        master-detail {
            transition: none;
            padding: var(--gutter);
        }

        @media only screen and (max-width: 900px) {
            master-detail {
                padding: var(--gutter-tiny) var(--gutter-tiny) 110px
                    var(--gutter-tiny);
            }
        }
    }
}

