page-results {
    .grid-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--gutter-small);
        margin-bottom: var(--gutter-small);

        section.card.grid-sub-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: var(--gutter-small);
            cursor: pointer;
            margin-bottom: 0;
            height: 100%;

            label {
                display: flex;
                align-items: center;
                gap: var(--gutter-mini);
            }

            figure.balance {
              img {
                height: 200px;
              }
            }

            figure.bell {
                background-color: var(--color-primary-300);
                width: 150px;
                height: 150px;
                border-radius: 44px;

                display: flex;
                align-items: center;
                justify-content: center;

                div {
                    position: relative;

                    span {
                        transform: translate(40%, -50%);
                        position: absolute;
                        right: 5px;
                        top: 5px;
                        z-index: 1;
                        background: var(--color-accent-200);
                        width: 30px;
                        height: 30px;
                        border-radius: 100%;
                        color: var(--color-primary-200);
                        font-size: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }

    a,
    a:visited {
      text-decoration: none;
      color: unset;
    }
}

html.is-mobile page-results .grid-list {
  grid-template-columns: 1fr;
}

page-results-performance,
page-results-personality,
page-results-potential,
page-results-topics {
  section.hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gutter-tiny);

    label {
      cursor: pointer;
      font-size: 20px;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: var(--gutter-tiny);
    }
  }
}
