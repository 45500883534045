steps-list {
    ol {
        list-style: none;
        counter-reset: ordered-list;
        padding-left: 0;

        li {
            display: flex;

            padding: var(--gutter-small);
            border-radius: var(--radius-large);
            background: var(--color-primary-100);
            margin-bottom: var(--gutter-small);

            p {
                margin: 0;
                background-color: unset;
            }
        }

        li:before {
            counter-increment: ordered-list;
            content: counter(ordered-list);
            font-weight: 600;
            margin-right: 10px;
            background-color: var(--color-primary-200);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 0;
            flex-shrink: 0;

            min-width: 30px;
            min-height: 30px;
            max-height: 30px;
            margin-top: auto;
            margin-bottom: auto;
        }
    }
}
