page-whats-new[widget] {
    h3 {
        margin-bottom: 0;
    }

    h4 {
        font-weight: 400;
    }

    ul {
        list-style: circle;
        margin: 1rem;
        margin-top: 0.4rem;
        padding: 0;
    }
}

widget-container {
    margin-top: -1rem;
    page-timeline {
        button[type="submit"] {
            margin-bottom: var(--gutter-tiny);
        }
    }

    notification-card:last-child .card {
        margin-bottom: 0;
    }
}

widget-container {
    [routekey] {

        .card{
            border-radius: 0;
        }


        > a {
            text-decoration: none;
        }

        h2 {
            background-color: rgba(0, 0, 0, 0.12);

            padding: 0.8rem 1.3rem 0.7rem;

            margin: 0;
            font-size: 1rem;
            border-radius: 1rem 1rem 0 0;
            min-height: 44px;

            --icon-fill-color: var(--color-primary-200);
            --icon-size: 17px;

            > a:link {
                &:hover {
                    --icon-fill-color: var(--color-accent-200);
                }
            }
        }

        > .card {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    .card {
        margin-bottom: 0;
    }

    .card[data-image],
    .card.background-image {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        background: linear-gradient(
                to bottom,
                rgba(
                        var(--color-surface-base-r, 0),
                        var(--color-surface-base-g, 0),
                        var(--color-surface-base-b, 0),
                        0
                    )
                    0% 60%,
                rgba(
                    var(--color-surface-base-r, 0),
                    var(--color-surface-base-g, 0),
                    var(--color-surface-base-b, 0),
                    1
                )
            ),
            var(--img, url(/assets/img/logo.svg));
        background-size: cover;
        background-position: center;
        border-radius: 0 0 1rem 1rem;
        height: 200px;
        width: 100%;

        > * {
            color: white;
            text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
        }

        h3 {
            margin: 0;

            margin-bottom: -0.6rem !important;
        }
    }
}

page-notifications[widget] {
    section.card {
        padding: var(--gutter-tiny);
        background-color: white;
        notification-card {
            background-color: transparent;
            profile-picture {
                margin: 0 var(--gutter-tiny) 0 0;
            }

            .info {
                max-height: 5rem;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                display: -webkit-box;
            }
        }
    }
}

widget-container {
    h3 {
        font-size: 16px;
        margin-bottom: 0.2rem;
    }
}
