comment-list-item {
    &:not([is-reply]) {
      position: relative;
    }

    &[is-reply] {
        margin-left: 28px;

        div.wrapper {
            margin-bottom: var(--gutter-tiny);

            &:last-of-type {
                margin-bottom: 0;
            }

            comment-social-interaction {
                margin-bottom: 0;
            }
        }
    }

    div.wrapper {
        margin-bottom: var(--gutter-smaller);
        display: flex;
        flex-direction: column;

        comment-author {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 14px;
            position: relative;
            gap: 8px;

            figure {
                width: 25px;
                height: 25px;
                margin: 0 2px 0 0;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    object-position: center;
                    object-fit: cover;
                    border: 2px solid var(--color-primary-200);
                    border-radius: 100%;
                    --border-size: 2px;
                }
            }
        }

        nav.comment {
            right: 0;
            position: absolute;
        }

        &:hover {
            nav.comment {
                opacity: 1;
            }
        }

        comment-content,
        comment-social-interaction {
            margin-left: 28px;
        }

        comment-content {
            p {
                margin: 0 0 var(--gutter-mini) 0;
            }
        }

        comment-social-interaction {
            display: flex;
            align-items: center;
            gap: var(--gutter-mini);
            margin-bottom: var(--gutter-mini);
        }

        button.reply {
            line-height: 20px;
            font-size: 14px;
            border-radius: var(--gutter-mini);
            min-height: 24px;

            &.load-more {
                align-self: flex-start;
                margin-left: 28px;
            }
        }

        span.separator {
            opacity: 0.5;
        }

        span.total {
            cursor: pointer;
        }
    }

    div.comment-rich-editor {
        display: flex;
        margin-left: 28px;
        gap: var(--gutter-tiny);
        margin-bottom: var(--gutter-tiny);

        post-rich-editor {
            flex: 1;
        }

        button {
            align-self: flex-end;
        }
    }

    div.svg-container {
        width: 28px;
        height: 100%;
    }
}
