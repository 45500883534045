.app-canvas {
    display: block;
    max-width: var(--max-canvas-width, 1000px);
    margin: 0 auto;
    background: var(--color-primary-100);

    ::placeholder {
        color: #999;
    }

    ::selection {
        background: var(--color-accent-200);
        color: white;
    }

    caret-color: var(--color-accent-200);

    main {
        height: 100%;
        position: relative;
        min-height: 100vh;
        padding: var(--gutter-small) var(--gutter-small) 100px
            var(--gutter-small);

        overflow-x: hidden;
    }

    footer {
        z-index: 12;
    }

    header {
        max-width: 800px;
        text-align: center;

        > *:last-child {
            margin-bottom: 0;
        }
    }
}

.is-authenticated {
    .app-canvas {
        main {
            padding: var(--gutter-small);
            transition: none;
        }
    }

    feedback-panel {
        display: block;
    }
}

.is-authenticated {
    .app-canvas {
        @media only screen and (max-width: 600px) {
            main {
                margin-left: 0;
            }
        }

        header {
            text-align: left;
            max-width: 100%;
        }
    }
}

section.hero {
    margin: 10px 10px;

    .center,
    &.center {
        text-align: center;
    }
}

@media only screen and (min-width: 900px) {
    main-grid spa-route section.hero {
        display: none;
    }
}

[itemprop="name"] {
    font-weight: bold;
    color: var(--color-accent-300);
}

span[data-label] {
    display: inline-block;
    border-radius: 0.5rem 0.5rem 0 0;
}

[hidden] {
    display: none;
}

[disabled] {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
}

input[autocomplete="one-time-code"] {
    &:not(:placeholder-shown) {
        font-weight: 800;
        letter-spacing: 1rem;
    }
}

.right {
    float: right;
}

h1.page-title {
    text-transform: uppercase;
    font-size: 18px;
    text-align: left;
    font-weight: 800;
    color: rgba(0, 0, 0, 0.17);
    letter-spacing: 0.1rem;
    margin-top: -5px;
    margin-bottom: 5px;
    letter-spacing: 0.4rem;
}

section {
    &.hero {
        h1,
        h2 {
            font-weight: 200;
            letter-spacing: 0.1rem;
        }

        h2 {
            font-size: 18px;
        }
    }
}

.flip-horizontal {
    transform: scaleX(-1);
}

:root {
    --widget-box-shadow: 0;
}

// TODO: move to design system
widget-container {
    --slot-padding: 0;
}

[hidden] {
    display: none !important;
}

div.widget.horizontal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

// TODO Refactor flex into design system flex.
.flex {
    gap: 1rem;
    display: flex;
    align-items: flex-start;
    flex-direction: row;

    &.spread {
        justify-content: space-between;
    }

    &.wrap {
        flex-wrap: wrap;
    }
}

/* glossary */

dfn {
    border-bottom: 2px dotted var(--color-accent-300);
    cursor: help;
    position: relative;
}

// TODO: Move to design package.
nav[data-dropdown].wide {
    width: 100%;
}

.empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--gutter);

    p {
        text-align: center;
    }
}

connection-search {
    .ac-itm:has(svg-icon[icon="diamond"]) {
        --ac-grid: 1fr 45px;
        padding: 10px;
    }
}

[routekey="/account"] {
    switch-check[name="privacy"] {
        margin-top: 2rem;
    }
}

@media only screen and (min-width: 601px) {
    h1.page-title {
        font-size: 32px;
    }

    [data-menu-appearance="minimal"] {
        qogni-app {
            padding-left: 70px;
            transition: padding-left 0.2s ease-in-out;
            will-change: padding-left;
        }
    }

    [data-menu-appearance="full"] {
        qogni-app {
            padding-left: 250px;
            transition: padding-left 0.2s ease-in-out;
            will-change: padding-left;
        }
    }
}

[data-flow-ui-type="full-page"] {
    global-menu {
        position: fixed;
        left: -9000px;
    }

    qogni-app {
        padding-left: unset;
    }
}

.close-flow-ui {
    position: fixed;
    z-index: 10;
    top: 10px;
    right: 10px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

#watermark {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    --icon-fill-color: rgba(0, 0, 0, 0.02);
    --icon-size: 70vmax;
    pointer-events: none;
}

html {
    #watermark {
        --icon-size: 70vh;
    }

    &.vw-landscape {
        #watermark {
            --icon-size: 70vw;
        }
    }
}

li {
    > small {
        display: block;
    }
}

.limited {
    max-height: 70vh;
    overflow-y: auto;
}

.tiles {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(198px, 1fr));
    gap: 0.5rem;

    > * {
        display: block;

        &:is(a) {
            text-decoration: none;
            color: inherit;
        }

        > .card {
            height: 200px;
        }
    }
}

p {
    a:link,
    a:visited {
        color: inherit;
        text-decoration: none;
        font-weight: 600;

        &:hover {
            text-decoration: underline;
        }
    }
}



.scroll-y {
    max-height: 400px !important;
    overflow-y: auto;
}