profile-progress {
    &[widget] {
        a.progress-wrap {
            width: 100%;
            margin-bottom: 0;
        }
    }

    .wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:not(:last-child){
            margin-bottom: var(--gutter-small);
        }
        h5,
        p {
            margin: 0;
        }
    }

    .progress {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background: var(--color-primary-100);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        font-weight: 600;
        font-size: 14px;
        border: 4px solid var(--color-accent-400);
    }

    .progress-wrap {
        min-width: 240px;
        background: var(--color-primary-100);
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 0 10px 20px;
        height: 60px;
        margin-bottom: var(--gutter-tiny);

        b{
            margin: 0 10px 0 0;
        }

        progress-indicator, .progress {
            margin-left: auto;
            height: 60px;
            width: 60px;
            margin-right: 0;
        }
        .progress {
            background: var(--color-primary-200);
        }
    }
    a.progress-wrap {
        font-style: normal;
        text-decoration: none;
        color: var(--color-text-000);

        &:hover {
            filter: brightness(90%);
            will-change: filter;
            transition: all .2s ease;
        }
    }

    button {
        margin-top: var(--gutter-small);
    }
}
