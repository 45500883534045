tour-popover {
    position: absolute;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #2e3a33;
    font-size: 18px;
    opacity:1;
    margin: var(--gutter-tiny);
    padding: var(--gutter-tiny);
}

.tour-highlighted {
    border: 5px solid #cf9954;
    border-radius: 5px;
    opacity:1;
}
