reactions-list-dialog {
    section.card {
        display: flex;
        width: 100%;

        align-items: center;
        justify-content: flex-start;
        gap: var(--gutter-tiny);
        background: var(--color-primary-100);
        margin-bottom: var(--gutter-tiny);

        .profile-pic-wrapper {
            display: flex;
            position: relative;

            .reaction-icon {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 20px;
                height: 20px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                --icon-fill-color: var(--color-text-200);

                &.like {
                    background: var(--color-accent-300);
                }

                &.love {
                    background: var(--color-accent-200);
                }

                &.idea {
                    background: var(--color-accent-100);
                }
            }
        }

        .info {
            display: flex;
            flex: 1;
        }

        a {
            text-decoration: unset;
            color: unset;
            line-height: 1;

            h4 {
                margin-bottom: 0;
            }
        }

        &.shimmer {
            app-shimmer {
                margin-bottom: 0;
            }

            .profile-pic-wrapper {
                display: block;

                app-shimmer.circle {
                    width: 40px;
                    height: 40px;
                }
            }

            .info app-shimmer {
                flex: 1;
            }
        }
    }

    tabs-indicator {
        margin-bottom: var(--gutter-tiny);
    }
}
