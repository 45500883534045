post-entry {
    width: 100%;

    &:has(nav.post > menu:not([hidden])) {
        z-index: 1;
    }

    section > flex-container {
        margin-bottom: var(--gutter-mini);
        position: relative;
    }

    section.card {
        padding: var(--gutter-small);

        &.tip {
            background-color: #fffdf9;
            > div {
                color: var(--color-text-000);
            }
        }
    }

    section.post,
    section.repost,
    section.tip {
        nav.post {
            position: absolute;
            right: 0;
            top: 5px;
        }

        &:hover {
            nav.post {
                opacity: 1;
            }
        }

        translatable-content {
            a,
            a:visited {
                text-decoration: none;
                color: var(--color-accent-300);
            }
        }
    }

    section.repost {
        padding-top: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: -15px;
        background: #e3dfd8;

        @media all and (max-width: 450px) {
            flex-item.time-ago {
                display: none;
            }
        }

        article-author figure img {
            border-color: transparent;
        }
    }

    comment-container {
        display: flex;
        border-top: 1px solid var(--color-border);
        margin-top: var(--gutter-tiny);
        margin-bottom: var(--gutter-tiny);
        padding-top: var(--gutter-tiny) ;
        gap: var(--gutter-tiny);

        post-rich-editor {
            flex: 1;
        }

        button {
            align-self: flex-end;
        }
    }

    comment-list[hide],
    comment-container[hide] {
        display: none !important;
    }

    comment-list {
        padding-top: var(--gutter-tiny);
    }

    social-interaction {
        flex-container {
            margin-bottom: 0;

            &.social-controls {
                margin-top: var(--gutter-tiny);
                margin-bottom: var(--gutter-tiny);
                padding-top: var(--gutter-tiny);
                border-top: 1px solid var(--color-border);
            }

            flex-item.rections {
                display: flex;
                gap: var(--gutter-mini);
                align-items: center;
                cursor: pointer;
            }

            div.comment-btn {
                cursor: pointer;
            }
        }

        svg-icon.red {
            --icon-fill-color: var(--color-accent-200) !important;
        }

        svg-icon.blue {
            --icon-fill-color: var(--color-accent-300) !important;
        }

        svg-icon.green {
            --icon-fill-color: var(--color-accent-400) !important;
        }

        button.active,
        button.active:focus {
            color: var(--color-primary-100);

            &.love {
                background: var(--color-accent);
            }

            &.like {
                background: var(--color-accent-300);
            }

            &.idea {
                background: var(--color-accent-400);
            }

            svg-icon {
                --icon-fill-color: var(--color-primary-200) !important;
            }
        }

        .grow {
            flex-grow: 1;
            flex-shrink: 1;
        }

        .last-edited {
            font-size: 12px;
            line-height: 1.2;
            text-align: end;
        }
    }

    article-author {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: var(--gutter-mini);
        font-size: 14px;
        position: relative;

        figure {
            width: 40px;
            height: 40px;
            position: relative;
            margin: 0 var(--gutter-mini) 0 0;

            img {
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: cover;
                border: 3px solid var(--color-primary-200);
                border-radius: 100%;
            }
        }

        profile-picture {
            margin-right: 8px;
        }
    }

    figure.article-image {
        margin-top: 0;
        img {
            margin: 0 calc(-1 * var(--gutter-small));
            width: calc(100% + (2 * var(--gutter-small)));
        }
    }

    a,
    a:visited {
        text-decoration: none;
        color: unset;
    }

    nav {
        opacity: 0;
        transition: opacity 300ms ease-in;
        align-self: flex-end;

        li.delete a {
            color: var(--color-accent-200);
            --icon-fill-color: var(--color-accent-200);

            display: flex;
            align-items: center;
        }
    }
}
