#sw-update-available {
    //display: none;

    position: fixed;

    text-align: center;

    &.show {
        display: block;
        animation: fade-in 1s ease-out;
    }

    >a {
        display: inline-block;
        padding: .2rem .5rem;
        cursor: pointer;
        background-color: var(--color-primary-100);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
        color: var(--color-primary-000);
        border-radius: .5rem;

        >span {
            display: inline-block;
            vertical-align: super;
            line-height: 32px;
        }

        >svg-icon {
            --icon-fill: var(--color-primary-000);
            display: inline-block;
        }
    }
}

.modeless {
    background-color: var(--color-primary-100);
    padding: 1rem;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
    z-index: 34;
    position: fixed;
    bottom: -400px;
    max-width: 700px;
    min-width: 300px;
    height: auto;
    max-height: 350px;
    left: 50%;
    transform: translateX(-50%);

    .logo {
        width: 70%;
        max-width: 300px;
    }

    &.active {

        bottom: 20px;
        transition: bottom .4s ease-out;
        transition-delay: 1s;
    }

    .close {
        cursor: pointer;
        position: absolute;
        top: 5px;
        right: 5px;

        &:hover {
            --icon-fill-color: red !important
        }
    }

    .name {
        width: 100%;
        small {
            line-height: 1.1rem;
            display: inline-block;
        }
    }

    .cta {
        svg-icon {
            display: inline-block;
        }
    }
}

div.sw-install {
    div.close {
        margin: 12px;
    }
}
