manage-emails {
    section.emails-list {
        margin-top: var(--gutter-small);
        padding-top: var(--gutter-small);
        border-top: 1px solid var(--color-primary-100);

        .emails-list-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 56px;
            gap: var(--gutter-mini);

            div.email-label {
                font-size: 16px;
                font-weight: 300;
                padding-left: var(--gutter-small);
            }

            div.trailing-part {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: var(--gutter-tiny);

                div.email-actions {
                    display: flex;
                    align-items: center;
                    gap: var(--gutter-mini);
                    padding-right: var(--gutter-tiny);

                    button.delete {
                        padding: 0.375rem;
                    }
                }
            }
        }

        form {
            border-top: 1px solid var(--color-border);
            padding-top: var(--gutter-small);
            margin-top: var(--gutter-small);
        }
    }
}

@media only screen and (max-width: 576px) {
    manage-emails section.emails-list .emails-list-item {
        flex-direction: column;
        gap: 0;

        div.email-label {
            padding-left: 0;
        }

        div.trailing-part {
            flex-direction: column;
            gap: var(--gutter-mini);
        }
    }
}

health-settings {
    padding-bottom: 2rem;
    h4 {
        margin-bottom: 0rem;
        &:not(:first-child) {
            margin-top: 1rem;
        }
    }
    ol.health-topics {
        padding-top: 0;
    }

    .diet {
        padding: 0;
        grid-template-columns: 210px 1fr;
    }
}

[data-range-output] {
    user-select: none;
    --gutter-tiny: 2px;
    
    fieldset.flex{
        gap: .4rem;
        flex-wrap: wrap;
    }

    label:has(input.variant2[type=radio]) span{
        padding: 3px 8px;
        
    }

    >span[data-label]{
        
        padding: 0!important;
        background-color: transparent!important;
        &::before{
            display: none;
        }
    }
}
