page-account-conversations {
    form,
    h4 {
        margin-bottom: 15px;
    }
}

page-messages {
    modal-dialog form {
        padding-top: 300px;

        connections-autocomplete {
            &.ac-container {
                .ac-suggestion {
                    &.ac-active {
                        max-height: 300px;
                        border-radius: 1rem;

                        .ac-itm {
                            border-radius: 1rem;

                            .text {
                                padding: .5rem 1.5rem;
                            }
                            .category {
                                padding: .7rem 2rem .5rem 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

page-single-thread {
    section.card {
        flex: 1;
        display: flex;
        flex-direction: column;
        min-height: 445px;
        margin-bottom: var(--gutter-mini);

        .header {
            display: flex;
            padding-bottom: var(--gutter-small);
            border-bottom: 1px solid var(--color-primary-100);

            figure.profile {
                margin: 0 var(--gutter-small) 0 0;
                position: relative;
                height: 50px;
                width: 50px;

                &.active:before {
                    background: var(--color-accent-400);
                }

                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 100%;
                }

                &:before {
                    content: "";
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 15px;
                    height: 15px;
                    background: transparent;
                    border-radius: 100%;
                    transition: all var(--transition-duration) ease;
                }
            }

            profile-picture {
                margin: 0 var(--gutter-small) 0 0;
                position: relative;

                &.active:before {
                    background: var(--color-accent-400);
                }

                &:before {
                    content: "";
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 15px;
                    height: 15px;
                    background: transparent;
                    border-radius: 100%;
                    z-index: 1;
                    transition: all var(--transition-duration) ease;
                }
            }

            .info {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: self-start;
                gap: 8px;
            }
            .info:not(:has(badge-tag)) {
                .title {
                    margin-top: 15px;
                }
            }

            .title {
                font-size: 14px;
                font-weight: 700;
                line-height: 17px;

                a {
                    color: unset;
                    text-decoration: none;
                }
            }

            .actions {
                figure {
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    --icon-fill-color: var(--color-primary-300);
                    background-color: var(--color-primary-100);
                    cursor: pointer;

                    svg-icon {
                        width: 50%;
                        height: 50%;
                    }

                    &.not-available-yet {
                        opacity: 0.5;
                        cursor: not-allowed;
                    }
                }
            }
        }

        .message-body,
        .user-select-body {
            flex: 1;
            overflow: auto;

            lit-virtualizer {
                hr::after {
                    font-size: 14px;
                    top: -2px;
                }

                div.shimmer {
                    width: 100%;
                }


                message-item  {
                    width: 100%;
                    .content {
                        display: flex;
                        gap: 0.7rem;
                        margin-bottom: 0.2rem;

                        section {
                            padding: 6px 8px;
                            border-radius: var(--gutter-tiny);
                            max-width: 60%;
                            display: flex;
                            flex-direction: column;
                            word-break: break-word;
                            font-size: 14px;

                            time {
                                text-align: right;
                                margin: 0;
                                font-size: x-small;
                                font-weight: 400;

                                align-self: flex-end;
                            }
                        }



                        nav {
                            opacity: 0;
                            transition: opacity 300ms ease-in;

                            li.delete a {
                                color: var(--color-accent-200);
                                --icon-fill-color: var(--color-accent-200);

                                display: flex;
                                align-items: center;
                            }
                        }

                        &:hover {
                            nav {
                                opacity: 1;
                            }
                        }
                    }

                    &.sender {
                        .content {
                            flex-direction: row-reverse;

                        section {
                            background-color: #f4f1ea;
                            color: black;
                        }
                        }
                    }

                    &.receiver {
                        .content {
                            section {
                                background-color: #f1f5f6;
                                color: black;
                            }
                        }
                    }

                    &.pending {
                        .content {
                            opacity: 0.8;
                        }
                    }

                    &.different-user {
                        .content {
                            margin-top: var(--gutter-tiny);
                        }
                    }

                }
            }

            new-message {
                font-size: 14px;
                font-weight: 700;
                line-height: 26px;
                padding-bottom: var(--gutter-mini);
                border-bottom: 1px solid var(--color-primary-100);
            }
        }

        .user-select-body {
            .connections {
            }
        }

        .footer {
            @media all and (max-width: 600px) {
                margin-bottom: 30px !important;
            }

            .typing {
                margin-left: 16px;
                margin-bottom: 12px;
                font-size: 14px;
                font-weight: 700;
                line-height: 17px;
            }

            form {
                position: relative;

                label {
                    margin-bottom: var(--gutter-tiny);

                    textarea {
                        resize: none;
                        margin: 0;
                        padding: 0.75rem 1.6rem;
                        padding-right: 60px;
                        border: 1px solid #ddd;
                    }
                }
            }

            figure,
            button {
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 54px;
                height: 54px;
                --icon-fill-color: var(--color-text-200);
                background-color: var(--color-primary-300);
                cursor: pointer;

                position: absolute;
                top: 0;
                right: 0;

                svg-icon {
                    width: 50%;
                    height: 50%;
                }
            }

            button {
                padding: 5px;

                svg-icon {
                    margin-right: 0;
                }
            }

            button:hover {
                svg-icon {
                    --icon-fill-color: var(--color-primary-400) !important;
                }
            }
        }
    }
}

main:has(page-single-thread) {
    display: flex;

    main-grid {
        display: flex;
        flex: 1;

        page-single-thread {
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
    }
}
