html {
    &:not(.is-authenticated),
    &.offline {
        .not-anonymous {
            display: none !important;
        }
    }

    &.is-mobile {
        user-select: none;
    }
}

[data-text] {
    display: inline-block
}

@media only screen and (max-width: 900px) {
    [data-text] {
        display: none;
    }
}

.preview-wrap {
    position: relative;
    padding: var(--gutter-small);

    &.small {
        padding: var(--gutter-tiny);

        .preview-content {
            margin-top: var(--gutter-small);
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.25);
        backdrop-filter: blur(var(--gutter-smaller));
        -webkit-backdrop-filter: blur(var(--gutter-smaller));
        z-index: 5;
        border-radius: var(--radius-large);
        overflow: hidden;
    }

    .preview-content {
        position: absolute;
        z-index: 10;
        text-align: center;
        margin-top: var(--gutter);
        color: var(--color-primary-200);
        text-shadow: 1px 1px 4px var(--color-primary-000);

        badge-tag,
        a.button {
            text-shadow: none;
        }
    }
}

html {
    body {
        overflow-y: hidden;
        &::before {
            content: "";
            position: fixed;
            top: 0;
            left: -100vw;
            width: 100vw;
            height: 6px;
            background-color: var(--color-accent-300);
            z-index: 999;
        }

        qogni-app {
            main {
                overflow-y: auto;
                max-height: 100vh;
            }

            badge-tag.environment {
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }

    }

    /* activate global progress bar */
    &.busy {
        body {
            overflow-x: hidden;

            &::before {
                animation: progress 1s linear infinite;
            }
        }
    }
}

/* Ghost UI */
.ghost {
    pointer-events: none;
    opacity: .6;
    transition: all .1s;
}

@keyframes progress {
    50% {
        left: 0;
    }

    100% {
        left: 100vw
    }
}


html:not(.is-mobile){
    .if-mobile {
        display: none;
    }
}


html.is-mobile{
    .if-not-mobile {
        display: none;
    }
}
