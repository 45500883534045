// Design System.
@use "@qogni-technologies/design-system/src/scss/variables";
@use "@qogni-technologies/design-system/src/scss/functions";
@use "@qogni-technologies/design-system/src/scss/theme";
@use "@qogni-technologies/design-system/src/scss/flex";

// Design System partials.
@use "@qogni-technologies/design-system/src/scss/partials/buttons";
@use "@qogni-technologies/design-system/src/scss/partials/radio-checkbox";
@use "@qogni-technologies/design-system/src/scss/partials/inputs";
@use "@qogni-technologies/design-system/src/scss/partials/form";
@use "@qogni-technologies/design-system/src/scss/partials/link-card";
@use "@qogni-technologies/design-system/src/scss/partials/points";
@use "@qogni-technologies/design-system/src/scss/partials/cards";
@use "@qogni-technologies/design-system/src/scss/partials/message-card";
@use "@qogni-technologies/design-system/src/scss/partials/notification-card";
@use "@qogni-technologies/design-system/src/scss/partials/badge-tag";
@use "@qogni-technologies/design-system/src/scss/partials/accordion";
@use "@qogni-technologies/design-system/src/scss/partials/images";
@use "@qogni-technologies/design-system/src/scss/partials/popover-dialog";
@use "@qogni-technologies/design-system/src/scss/partials/autocomplete";

@use "@qogni-technologies/design-system/src/scss/partials/filter-options";
@use "@qogni-technologies/design-system/src/scss/partials/shimmer-effect";
@use "@qogni-technologies/design-system/src/scss/partials/infinite-list";
@use "@qogni-technologies/design-system/src/scss/partials/program-check-list";
@use "@qogni-technologies/design-system/src/scss/partials/multi-answer-card";
@use "@qogni-technologies/design-system/src/scss/partials/tooltip";
@use "@qogni-technologies/design-system/src/scss/partials/range-rating.scss";
@use "@qogni-technologies/design-system/src/scss/partials/fab-button";
@use "@qogni-technologies/design-system/src/scss/partials/dropdown-button";
@use "@qogni-technologies/design-system/src/scss/partials/post-reactions.scss";

// Margin and padding classes.
@use "@qogni-technologies/design-system/src/scss/margins";
@use "@qogni-technologies/design-system/src/scss/paddings";

// autocomplete
@use "@qogni-technologies/design-system/src/scss/partials/tag-list";

// service-worker (setup/new version prompt)
@use "service-worker";

// Local vars, styles and partials.
@forward "variables";
@use "app";
@use "tools";
@use "typography";
@use "functional";
@use "view-transitions";
@use "accessibility";
@use "partials";

// TODO - temp fixes (need to be fixed in design system)

flex-item,
.flex-item {
    overflow-x: visible;
}

jobs-autocomplete {
    --ac-grid: 1fr;

    &.ac-container {
        .ac-suggestion {
            &.ac-active {
                max-height: 300px;
                border-radius: 1rem;

                .ac-itm {
                    border-radius: 1rem;
                    .text {
                        padding: 0.6rem 1rem;
                    }
                }
            }
        }
    }
}

tag-list[name="goals"] {
    --ac-grid: 0 1fr;
}

[data-dropdown] {
    &.compact {
        menu li a,
        ul li a {
            padding: 0.3rem 0.6rem !important;
        }
    }
}

[data-dropdown] {
    &.compact {
        button.filter-button {
            padding: 15px 20px;
        }

        menu:not([hidden]) {
            border: 2px solid white;
            max-height: 300px !important;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
            overflow-y: auto !important;
        }
    }
}

