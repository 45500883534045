page-ai-buddy {
    .pre-wrap:has(.tiles, p, ul, ol) {
        white-space: inherit;
    }

    flow-ui[type="chat"] [data-flow-inner] flow-ui-step:not(.actor-user) {
        &:has(.tiles) {
            background-color: transparent;
        }

        &:has(button) {
            background-color: transparent;
            flex: 1;
            display: flex;
            justify-content: center;


        }
    }

    .tiles {
        > * {
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 1rem;
            padding: 1rem;
            cursor: pointer;

            &:nth-child(1) {
                background-color: var(--color-pastel-accent-100);
            }
            &:nth-child(2) {
                background-color: var(--color-pastel-accent-200);
            }
            &:nth-child(3) {
                background-color: var(--color-pastel-accent-300);
            }
        }
    }


    flow-ui[type=chat] [data-flow-inner] flow-ui-step.running:has(form){
        form {
            margin-left: 0;
            width: 100%;

            fieldset {
                margin: 0 0 0 0;
            }
        }
    }

    flow-ui[type=chat] [data-flow-inner] flow-ui-step.disclaimer {
        font-size: 0.8rem;
        padding-top: 0;
        padding-bottom: 0;
        color: rgba(0, 0, 0, 0.5);
        background: transparent;
    }

    flow-ui[type=chat] [data-flow-inner] flow-ui-step.actor-user {
        width: 100%;
    }

    flow-ui[type=chat] [data-flow-inner] flow-ui-step {
        p {
            margin-bottom: var(--gutter-tiny);
        }
        ol, ul {
            margin: var(--gutter-tiny) 0;
        }
    }

    button.menu-btn {
        z-index: 1;
    }

    hr[data-content]:after {
        font-size: 14px;
        top: -2px;
    }
}

