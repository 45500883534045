icon-hero {
    margin-bottom: var(--gutter);
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    img {
        margin-right: var(--gutter-small);
        width: 150px;
    }

    h1 {
        text-align: left;
        margin-bottom: var(--gutter-tiny);
    }
}
