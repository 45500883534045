workout-search {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: var(--gutter-small);

    & > * {
        margin-bottom: 0;
    }
}

workout-card {
    width: 100%;

    &:hover,
    &:focus {
        border-color: var(--color-primary-000);
    }

    &:has(menu:not([hidden])) {
        z-index: 1;
    }

    &:last-child {
        margin-bottom: 0;
    }
    article a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-decoration: none;
        color: var(--color-text-000);

        background: var(--color-primary-200);
        border-radius: var(--radius-large);
        padding: var(--gutter-smaller);
        margin-bottom: var(--gutter-small);
        border: 1px solid var(--color-primary-200);
        transition: border var(--transition-duration) ease-in-out;

        &:active {
            box-shadow: 3px 3px var(--color-primary-400);
        }

        .info {
            overflow: hidden;

            .name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 14px;
                font-weight: 700;
                line-height: 17px;
                margin-bottom: 9px;
            }
        }

        badge-tag {
            margin-bottom: 8px;
            line-height: normal;
        }

        figure {
            width: 140px;
            height: 85px;
            position: relative;
            display: block;
            margin-right: var(--gutter-small);
            flex-grow: 0;
            flex-shrink: 0;
            border-radius: var(--radius-small);
            overflow: hidden;
            align-self: stretch;

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .meta {
            font-size: 14px;
            font-weight: 250;
            line-height: 17px;

            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg-icon {
                margin-right: 10px;
            }
        }
    }

    article {
        position: relative;

        .action-wrapper {
            position: absolute;
            top: var(--gutter-smaller);
            right: var(--gutter-smaller);
        }
    }
}

page-single-workout {
    .heading {
        display: flex;

        h1 {
            flex: 1;
        }

        button {
            align-self: flex-start;

            &.round.small {
                padding: 0.5rem;
            }
        }
    }
}
