.icon {
    width: 24px;
    height: 24px;
}

.v-bottom {
    position: fixed;
    bottom: 15px;
    left: 10%;
    right: 10%;
}

.mb-small {
    margin-bottom: var(--gutter-small);
}
.mb-tiny {
    margin-bottom: var(--gutter-tiny);
}
.mb-large {
    margin-bottom: var(--gutter-large);
}
.mb {
    margin-bottom: var(--gutter);
}
.mb-none {
    margin-bottom: 0;
}
.mt-small {
    margin-top: var(--gutter-small);
}
.mt-tiny {
    margin-top: var(--gutter-tiny);
}
.mt-large {
    margin-top: var(--gutter-large);
}
.mt {
    margin-top: var(--gutter);
}
.mt-none {
    margin-top: 0;
}

.center {
    text-align: center;
}

.narrow {
    max-width: 600px;
    margin: 0 auto;
}

hr {
    &[data-content] {
        min-width: 200px;
        font-size: large;
        position: relative;
        margin-top: 0;
        margin-bottom: var(--gutter-tiny);
        outline: 0;
        border: 0;
        color: var(--color-text-000);
        text-align: center;
        opacity: .8;
        width: 100%;

        &:before {
            content: '';
            background: linear-gradient(to right, transparent, #818078, transparent);
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 1px;
            min-width: 200px;
        }

        &:after {
            content: attr(data-content);
            position: relative;
            display: inline-block;
            padding: 0 .5em;
            line-height: 1.5em;
            color: #818078;
            background-color: #fcfcfa;
        }
    }
}
