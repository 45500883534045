slide-panel {
    z-index: 20;

    h3 {
        border-radius: 1rem 1rem 0 0;
        margin: 0;
        text-align: center;
        margin-bottom: 1rem;
        padding: 0.4rem 0.8rem;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    article {
        padding: 1rem;
    }

    ul {
        //margin-top: rem;
        list-style: none;
        padding: 0
    }

    li[data-tag] {
        margin: 0;
        padding: 0.5rem;
        border-radius: 0.5rem;
        margin-bottom: 1rem;
        transition: all var(--transition-duration) ease-in-out;
        border: 2px dotted transparent;

        h4 {
            display: flex;
            flex-direction: row;
            gap: 1rem;
        }

        &.selected {
            //background-color: #333;
            border: 2px dotted var(--color-accent-300);
        }
    }

    article {
        background-color: var(--color-primary-200);
    }

    header {
        margin-top: 2rem;

        img {
            max-height: 120px;
            object-fit: contain;
            object-position: center;
            align-self: center;
            margin: auto;
            width: 100%;
        }
    }

    footer {
        margin-top: 1rem;

        a {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;

            svg-icon {
                --icon-size: 24px;
            }
        }
    }

    .badges {
        margin-top: 0.5rem;
        margin-bottom: 0;
        font-size: smaller;
        display: flex;
        flex-wrap: wrap;
        gap: .6rem
    }
}
