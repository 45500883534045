section circle-link {
    margin-bottom: 0;
}

circle-link {
    display: block;
    width: 100%;
    max-width: 200px;
    margin: 0 auto var(--gutter-small) auto;
    position: relative;
    border-radius: 100%;
    border: 5px solid var(--color-primary-000);
    background: var(--color-primary-200);

    &:before {
        content: "";
        padding-top: 100%;
        display: block;
    }

    a {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: stretch;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        text-decoration: none;

        svg-icon {
            margin: 0 auto;
            --icon-fill-color: rgba(0, 48, 75, 0.3);
        }

        > *:last-child {
            margin-bottom: 0;
        }

        h3 {
            margin-bottom: 0;
        }
    }

    &.grey {
        border-color: rgba(0, 48, 75, 0.05);

        * {
            color: rgba(0, 48, 75, 0.05);
        }
    }

    &.red {
        border-color: var(--color-accent-200);

        * {
            color: var(--color-accent-200);
        }
    }

    &.yellow {
        border-color: var(--color-accent-100);

        * {
            color: var(--color-accent-100);
        }
    }

    &.green {
        border-color: var(--color-accent-400);

        * {
            color: var(--color-accent-400);
        }
    }

    &.light-blue {
        border-color: var(--color-accent-300);

        * {
            color: var(--color-accent-300);
        }
    }

    .icon:before {
        font-size: 80px;
    }
}

.circle-add-new-user-form {
    margin-bottom: var(--gutter-small);
}

page-network {
    details[data-type="circles"] {
        flex-container {
            flex-item section.card {
                border-radius: var(--radius-larger);
                background: var(--color-primary-200);
            }
        }
    }
}

page-network-circles-id {
    section.card.graph {
        position: relative;

        .graph-overview {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: var(--gutter-small);
            margin-bottom: var(--gutter);
            flex-wrap: wrap;

            span {
                display: flex;
                align-items: center;
                gap: var(--gutter-tiny);

                figure {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                }

                &.your-score figure {
                    background-color: #64c8ff;
                }

                &.other figure {
                    background-color: var(--color-primary-300);
                }
            }
        }

        .preview-content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 10;
            text-align: center;
            margin-top: var(--gutter-large);
            color: var(--color-primary-200);
        }

        &:has(.preview-content)::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #00000040;
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
            z-index: 1;
            border-radius: var(--radius-large);
            overflow: hidden;
        }
    }

    details.red {
        section.card {
            margin-bottom: 0;
        }
    }
}
