section channel-list-item a {
    margin-bottom: 0;
}

page-network accordion-list {
    details.channels flex-item > section.card {
        padding: 5px;
    }
}

channel-list-item {
    width: 100%;
    display: block;

    a {
        display: flex;
        gap: 1rem;
        align-items: center;
        flex-direction: row;
        width: 100%;
        text-decoration: unset;
        color: unset;
        padding: var(--gutter-smaller) 15px;
        background: var(--color-primary-200);
        border-radius: var(--radius-large);
        margin-bottom: 20px;

        h4 {
            padding: 0;
            margin: 0;
            color: var(--color-text-000);
        }
    }

    button {
        margin-left: auto;
    }
}

page-results channel-list-item:not(:last-child) {
    margin-bottom: var(--gutter-smaller);
}

page-network-channels-id {
    .channel-add-new-user-form {
        margin-bottom: var(--gutter-small);
    }
}

page-network {
    .network-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--gutter-smaller);
        margin-bottom: var(--gutter-small);
    }
}

html.is-mobile page-network .network-grid {
    grid-template-columns: 1fr;
}

page-network-channels {
    section.hero {
        justify-content: space-between;
        align-items: center;
        margin-bottom: var(--gutter-small);

        h1 {
            margin: 0;
        }
    }
}