recipe-search {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: var(--gutter-small);

    & > * {
        margin-bottom: 0;
    }
}

recipe-card {
    width: 100%;

    &:hover,
    &:focus {
        border-color: var(--color-primary-000);
    }

    &:has(menu:not([hidden])) {
        z-index: 1;
    }

    &:last-child {
        margin-bottom: 0;
    }

    article a {
        display: flex;
        justify-content: flex-start;
        text-decoration: none;
        color: var(--color-text-000);
        padding: var(--gutter-small);

        background: var(--color-primary-200);
        border-radius: var(--radius-large);
        margin-bottom: var(--gutter-small);
        border: 1px solid var(--color-primary-200);
        transition: border var(--transition-duration) ease-in-out;

        &:active {
            box-shadow: 3px 3px var(--color-primary-400);
        }

        figure {
            width: 140px;
            height: 140px;
            position: relative;
            display: block;
            margin-right: var(--gutter-small);
            flex-grow: 0;
            flex-shrink: 0;
            border-radius: var(--radius-small);
            overflow: hidden;

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .info {
            flex: 1;
        }

        .meta {
            font-size: 14px;

            div {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 5px;

                &:last-child {
                    margin-bottom: 0;
                }

                svg-icon {
                    margin-right: 10px;
                }
            }
        }
    }

    .info hgroup {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 var(--gutter-tiny);

        flex-container {
            margin: 0;
            gap: var(--gutter-mini);

            flex-item {
                margin: 0;
            }
        }
    }
}

page-single-recipe {
    .heading {
        display: flex;

        h1 {
            flex: 1;
        }

        button {
            align-self: flex-start;
            z-index: 1;

            &.round.small {
                padding: 0.5rem;

                &[data-tooltip][data-tooltip]::after {
                    white-space: pre-wrap;
                    text-align: start;
                    padding: var(--gutter-tiny);
                    line-height: 1.5rem;
                    font-size: 16px;
                }

                &:not(:last-of-type) {
                    margin-right: var(--gutter-tiny);
                }
            }
        }
    }

    div.ingredient-item-wrapper {
        display: flex;
        align-items: center;
        gap: var(--gutter-mini);
        margin-bottom: var(--gutter-tiny);

        label:has(input.variant1[type=checkbox]) {
            display: inline-flex;
            margin: 0;
        }
    }
    
    badge-tag:has(svg-icon) svg-icon {
        position: unset;
    }
}

html.is-mobile page-single-recipe {
    div.ingredient-item-wrapper {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: var(--gutter-smaller);

    }
}

recipe-range-rating-view {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gutter-mini);

    a {
        text-decoration: none;
        color: unset;
        display: flex;
        align-items: center;
        gap: var(--gutter-tiny);
    }
}
