dl {
    display: grid;
    grid-template-columns: 150px 1fr;

    dt {
        font-weight: bold;
        text-align: right;
        overflow: hidden;
        white-space: pre;
        text-overflow: ellipsis;
        color: var(--color-primary-000, currentColor);

        &:after {
            content: ": "
        }
    }

    dd {
        font-weight: 600;
    }
}

