/* Qogni Flows */

@use "@pwf/pure-work/src/scss/flow";

/* full page flow */
[data-flow-ui-type="full-page"] {
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    overflow-y: scroll;

    fab-button {
        display: none;
    }

    [data-flow-inner] {
        overflow-y: hidden;
        position: relative;

        &::after {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: calc(100% - 220px);
            background-position: center center;
            background-image: url("/assets/img/ico-128.png");
            background-repeat: no-repeat;
            opacity: 0;
            transition: opacity 0.4s ease-in-out;
            transition-delay: 0.2s;
            pointer-events: none;
        }
    }
}

/* specific flows */

[data-flow] {
    [data-flow-continue] {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
    }

    .listening {
        .enable-speech {
            background-color: var(--color-accent-300);

            &::before {
                animation: listening 1.3s infinite;
                background-color: var(--color-accent-300);
            }

            svg-icon {
                --icon-fill-color: var(--color-primary-200);
            }
        }
    }

    form {
        background-color: rgba(255, 255, 255, .5);
        padding: 1rem;
        border-radius: var(--radius-large);

        legend {
            margin-bottom: 0;
            width: 100% !important; // safari fix
            display: block !important; // safari fix
        }
        legend.health-info {
            width: auto !important; // safari fix
            display: block !important; // safari fix
        }

        label {
            font-size: 1.2rem;
            &:empty {
                display: none;
            }

            > span {
                line-height: 2rem;
            }
        }

        textarea {
            background-color: transparent;
            &.large {
                min-width: 330px;
                min-height: 15rem;
                resize: vertical;
            }
        }
    }

    h3 {
        margin: 0;
        margin-bottom: 1rem;
        text-align: center;
    }

    blockquote {
        border-radius: 1rem;
        font-size: 1.3em;
        max-width: 400px;
        margin: 2rem auto;
        font-family: Open Sans;
        font-style: italic;
        color: #555555;
        padding: 1.2em 30px 1.2em 75px;
        border-left: 8px solid var(--color-accent-200);
        line-height: 1.2;
        position: relative;
        background: var(--color-primary-100);

        &::before {
            font-family: Arial;
            content: "\201C";
            color: var(--color-accent-200);
            font-size: 4em;
            position: absolute;
            left: 10px;
            top: -10px;
        }

        &::after {
            content: "";
        }

        span {
            display: block;
            color: #333333;
            font-style: normal;
            font-weight: bold;
            margin-top: 1em;
        }
    }

    .callout {
        max-width: 350px;
        background-color: white;
        border-radius: 1rem;
        padding: 1rem;
    }

    .footnotes {
        color: #666;
        font-size: smaller;

        dl {
            display: flex;
            flex-direction: row;
            gap: 1rem;

            dt::after {
                content: unset;
            }

            dt {
                margin: 0;
            }

            dd {
                font-weight: 400;
                font-style: italic;
                margin: 0;
            }
        }
    }
    .health-info {
        display: block;
        text-align: center;
        font-size: 1.5rem;

        &[data-rating="1"] {
            color: var(--color-accent-200);
        }
        &[data-rating="2"] {
            color: var(--color-accent-200);
        }
        &[data-rating="3"] {
            color: var(--color-accent-300);
        }
        &[data-rating="4"] {
            color: var(--color-accent-300);
        }
        &[data-rating="5"] {
            color: var(--color-accent-400);
        }
    }

    social-rating {
        margin: auto;
        border-radius: 100px;
        outline: 1px dotted #ccc;
    }

    multi-select {
        display: flex;
        flex-wrap: wrap;
        gap: 0.2rem;

        label {
            user-select: none;
            margin-bottom: 0.2rem;
            line-height: 1.1rem;
            cursor: pointer;
            display: inline-block;
            width: calc(50% - 1rem);

            input[type="checkbox"] {
                display: block !important;
                position: absolute;
                opacity: 0;
            }
            input:focus + span {
                outline: 1px dotted #ccc;
            }
        }

        @media all and (max-width: 600px) {
            label {
                width: 100% !important;
            }
        }

        &[autosort] {
            label {
                transition: all 0.3s ease-in-out;
                transition-delay: 0.5s;

                span[data-label] {
                    display: inline-block;
                    width: 45%;
                }

                &.checked {
                    width: 100%;
                    transition: all 0.3s ease-in-out;
                    order: var(--order, -1);
                }

                input[type="checkbox"]:not(:checked) + span {
                    font-size: 1rem;
                    padding: 0.4rem 0.8rem;
                    display: inline-block;
                    width: 100%;

                    &::before {
                        content: unset;
                        padding: 0.4rem 0.8rem;
                    }
                }

                input[type="checkbox"]:checked + span {
                    font-weight: 400;

                    &::before {
                        content: var(--order-name, "");
                        color: black;
                        font-weight: 800;
                        font-size: 18px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }

    date-text {
        width: 300px;
        margin: auto;
    }

    jobs-autocomplete {
        height: 360px;
        --ac-grid: 0 1fr;

        .ac-suggestion {
            top: 0 !important;
            position: relative !important;
            max-height: 300px;
        }
        input {
            width: 400px;
            max-width: 80vw;
        }
    }

    video {
        max-height: 600px;
    }

    article {
        max-height: 80vh;
        overflow-y: scroll;
    }

    /* ACCESSIBILITY */
    radio-group {
        .radio-group {
            flex-wrap: wrap;
        }
        &.horizontal {
            flex-wrap: wrap;
            justify-content: center;
            margin: 0;
        }

        label {
            // width: 150px;
            cursor: pointer;
            span {
                line-height: 1.1rem;
                background: var(--color-primary-100);
                appearance: none;
                -webkit-appearance: none;
                border-radius: 100px;
                font-family: var(--font-body);
                font-weight: 400;
                font-size: 1rem;
                width: 100%;
                color: var(--color-primary-000);
                opacity: 1;
                margin: 0;
                display: inline-flex;
                align-items: center;
                justify-content: flex-start;

                &::before {
                    aspect-ratio: 1/1 !important;
                }
            }
        }

        input[type="radio"] {
            display: block !important;
            position: absolute;
            opacity: 0;

            &:checked + span {
                background: var(--color-accent-300);
                color: var(--color-primary-200);
            }
        }
        input:focus + span {
            outline: 1px dotted #ccc;
        }
    }
}

@media (max-width: 600px) {
    [data-flow] {
        margin-top: -40px; // to make 'center' center on mobile, with the fixed button area ([data-flow-continue])

        [data-flow-inner] {
            [data-flow-continue] {
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 1rem;
            }

            flow-ui-step {
                &[data-step="onboardQogniUser.ui.wf9"],
                &[data-step="onboardQogniUser.ui.wfc"] {
                    overflow: hidden;

                    form {
                        height: calc(100vh - 150px);

                        fieldset {
                            flex: 1;
                            overflow: auto;
                        }
                    }
                }

                &.completing {
                    [data-flow-continue] {
                        opacity: 0;
                    }
                }
            }

            multi-select input[type="checkbox"] + span {
                padding: 5px 10px !important;
            }
        }
    }
}

@keyframes listening {
    from {
        opacity: 0.25;
    }
    to {
        transform: scale(2);
        opacity: 0;
    }
}
