group-item {
    background: var(--color-primary-200);
    padding: var(--gutter-smaller) 15px;
    border-radius: var(--radius-large);

    display: flex;
    gap: var(--gutter-mini);
    align-items: center;
    flex-direction: row;
    width: 100%;

    svg-icon {
        margin-right: var(--gutter-tiny);
    }

    a {
        text-decoration: unset;
        color: unset;

        h4 {
            padding: 0;
            margin: 0;
        }
    }

    button.align-end,
    badge-tag.align-end {
        margin-left: auto;
    }
}

page-network-groups {
    div.heading {
        display: flex;
        align-items: flex-start;
        margin: 10px;

        h1 {
            flex: 1;
            text-transform: uppercase;
            font-size: 20px;
            text-align: left;
            font-weight: 200;
            letter-spacing: 0.1rem;
        }
    }

    group-item {
        margin-bottom: var(--gutter-smaller);
    }
}
