manage-users {
    user-item {
        background: var(--color-primary-100);
        padding: var(--gutter-tiny) var(--gutter-small) var(--gutter-tiny)
            var(--gutter-tiny);
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: var(--gutter-tiny);
        font-size: 14px;
        font-weight: 400;

        .controls {
            margin-left: auto;
            flex-shrink: 0;
            flex-grow: 0;
            text-align: right;

            nav {
                display: flex;

                menu li.red a {
                    color: var(--color-accent-200);
                    --icon-fill-color: var(--color-accent-200);
                    display: flex;
                    align-items: center;
                }
            }
        }

        .info {
            display: flex;
            gap: var(--gutter-mini);
            margin-right: var(--gutter-small);

            h4 {
                margin-bottom: 0;
            }

            badge-tag {
                text-transform: capitalize;
            }
        }

        figure {
            display: block;
            margin-right: var(--gutter-small);
            flex-shrink: 0;
            flex-grow: 0;
            position: relative;

            img {
                display: block;
                width: 50px;
                height: 50px;
                border-radius: 100%;
            }
        }

        profile-picture {
            margin-right: var(--gutter-small);
        }
    }
}
